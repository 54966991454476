import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import ContainerSite from "../3_layouts/ContainerSite"
import IconCopyright from "../5_assets/svg/icons/copyright.svg"
import IconLinkedin from "../5_assets/svg/icons/linkedin.svg"
import IconInstagram from "../5_assets/svg/icons/instagram.svg"
import IconMail from "../5_assets/svg/icons/mail-fill.svg"

const footerLinks = [
   { label: "Case studies", to: "/#work", aria: "Link to work section" },
   { label: "About", to: "/about", aria: "Link to about section" },
   { label: "Recommendations", to: "/about#recommendations", aria: "Link to recommendations section" },
   { label: "Contact", to: "/about#contact", aria: "Link to contact section" },
]

const socialIcons = [
   { href: "https://www.linkedin.com/in/paolo-todde/", icon: IconLinkedin, label: "LinkedIn" },
   { href: "https://www.instagram.com/paolo__todde/", icon: IconInstagram, label: "Instagram" },
   { href: "mailto:room01info@gmail.com", icon: IconMail, label: "Email" },
]

const FooterLink = ({ to, label, aria }) => (
   <li aria-label={aria}>
      <AnchorLink to={to} title={label}>
         <p className="body-sm font-mono uppercase text-link hover:underlined">{label}</p>
      </AnchorLink>
   </li>
)

const SocialIcon = ({ href, icon: Icon, label }) => (
   <li className="group ">
      <a
         className="text-link"
         title={label}
         aria-label={`${label} icon`}
         href={href}
         target="_blank"
         rel="noopener noreferrer"
      >
         <Icon alt={`${label} icon`} className="w-6" />
      </a>
   </li>
)

const ExternalLink = ({ href, label, children }) => (
   <a className="text-link" href={href} target="_blank" rel="noopener noreferrer" aria-label={`Link to ${label}`}>
      {children}
   </a>
)

const Footer = () => {
   const currentYear = new Date().getFullYear()

   return (
      <footer className="w-full space-bottom-xl text-greyStandard" role="group" aria-label="Footer">
         <ContainerSite>
            <div className="flex flex-col md:flex-row justify-between space-y-16 md:space-y-0 space-top-md space-bottom-md border-t-2 border-grey-400">
               <div className="flex flex-col space-y-1 items-center md:items-start lg:p-0">
                  <p>
                     Designed in{" "}
                     <ExternalLink href="https://www.figma.com/" label="Figma">
                        Figma
                     </ExternalLink>
                  </p>
                  <p>
                     Coded with{" "}
                     <ExternalLink href="https://www.gatsbyjs.com/" label="Gatsby">
                        Gatsby(React.js)
                     </ExternalLink>
                  </p>
                  <p>
                     Styled with{" "}
                     <ExternalLink href="https://tailwindcss.com/" label="Tailwindcss">
                        Tailwind CSS
                     </ExternalLink>
                  </p>
                  <p className="flex items-center space-x-0 space-top-sm">
                     <IconCopyright className="w-5 h-full inline-flex md:mr-1" />
                     <span>{currentYear}</span>
                  </p>
               </div>

               <ul className="flex space-x-5 justify-center">
                  {socialIcons.map((icon, index) => (
                     <SocialIcon key={index} {...icon} />
                  ))}
               </ul>

               <ul className="flex flex-col justify-between space-y-3 items-center md:items-start">
                  {footerLinks.map((link, index) => (
                     <FooterLink key={index} {...link} />
                  ))}
               </ul>
            </div>
         </ContainerSite>
      </footer>
   )
}

export default Footer
