import React, { useState, createContext, useContext } from "react"
import PropTypes from "prop-types"
import ContainerSite from "../3_layouts/ContainerSite"
import Navigation from "../2_sections/Navigation"
import Footer from "../2_sections/Footer"
import TransitionPageIn from "../0_transitions/TransitionPageIn.js"
import ScrollToTopButton from "../1_components/ScrollToTopButton"
import BackgroundPattern from "../5_assets/static/BackgroundPattern.js"
import DarkThemeSwitch from "../1_components/DarkThemeSwitch.js"

export const DarkThemeContext = createContext()

const Layout = ({ children, location }) => {
   // const allowedPathsBackgroundPattern = ["/", "/about/", "/404/", "/blog/"]
   // const showBackgroundPattern = allowedPathsBackgroundPattern.includes(location.pathname)

   const [darkMode, setDarkMode] = useState(true)
   const toggleDarkMode = () => {
      setDarkMode(!darkMode)
   }

   return (
      <DarkThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
         <div className={`${darkMode && "dark"} relative h-full w-full overflow-hidden bgColorBase text-colour`}>
            <Navigation />
            <TransitionPageIn>
               <BackgroundPattern />
               <main>
                  <ContainerSite>{children}</ContainerSite>
               </main>
            </TransitionPageIn>

            <div className={`fixed bottom-[8%] right-[6%] flex space-x-2 p-7 pb-0 pr-0`}>
               <ScrollToTopButton />
               <DarkThemeSwitch />
            </div>

            <Footer />
         </div>
      </DarkThemeContext.Provider>
   )
}

Layout.propTypes = {
   children: PropTypes.node.isRequired,
   location: PropTypes.object.isRequired,
}

export default Layout
