import React, { useContext } from "react"
import IconSun from "../5_assets/svg/icons/sun.svg"
import IconMoon from "../5_assets/svg/icons/moon.svg"
import { DarkThemeContext } from "../../src/3_layouts/index.js"

const DarkThemeSwitch = () => {
   const { darkMode, toggleDarkMode } = useContext(DarkThemeContext)

   return (
      <button
         aria-label="Toggle Dark Mode"
         title="Toggle Dark Mode"
         className={`
        inline-block focus:outline-none mx-auto
        backdrop-blur-sm p-2.5 rounded-full
        border-2 border-dark dark:border-grey-400 hover:border-white
        bgColorBase text-greyStandard hover:text-white shadow-hover
        transform hover:-translate-y-1 transition ease-in-out duration-500
      `}
         onClick={toggleDarkMode}
      >
         {!darkMode ? <IconMoon className="w-8" alt="Moon icon" /> : <IconSun className="w-8" alt="Sun icon" />}
      </button>
   )
}

export default DarkThemeSwitch
