import React from "react"
import PatternImg from "../../5_assets/svg/pattern_2.svg"

function BackgroundPattern({ height = "40rem", fadeColor = "offWhite" }) {
   const darkModeClass = fadeColor === "offWhite" ? "dark:from-dark dark:via-dark/20" : ""

   return (
      <div
         className={`absolute -z-50 h-[${height}] left-1/2
                   transform -translate-x-1/2 overflow-y-hidden
                   -top-[15rem] xs:-top-[18rem] md:-top-[10rem] lg:-top-[10rem] xl:-top-[10rem]
                   w-[400%] xxs:w-[400%] xs:w-[250%] sm:w-[170%] md:w-[150%] lg:w-[150%] xl:w-[150%]
                   opacity-100 dark:opacity-50 ${darkModeClass}`}
      >
         <span
            className={`absolute top-0 inset-0
                    bg-gradient-to-t
                    from-${fadeColor}
                    via-${fadeColor}/40
                    to-transparent ${darkModeClass}`}
         ></span>
         <PatternImg />
      </div>
   )
}

export default BackgroundPattern
